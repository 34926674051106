var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-card',{staticClass:"shadow-none"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full sm:w-1/2 md:w-2/3 m-auto flex",staticStyle:{"width":"calc(100% - 110px) !important"}},[_c('div',{staticClass:"w-1/3 md:flex m-auto relative card-logo",staticStyle:{"text-align":"center !important"}},[_c('img',{staticClass:"rounded-full shadow-md m-auto",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.getImageUrl(_vm.organization),"height":"50px","width":"50px"}})]),_c('div',{staticClass:"flex w-full informations"},[(_vm.app)?_c('div',{staticClass:"w-full w-2/3 text-center md:text-left md:ml-3"},[_c('h4',{staticClass:"title-sinao font-bold cursor-pointer",staticStyle:{"text-align":"left !important"}},[_c('a',{staticClass:"title-sinao",attrs:{"href":_vm.app.url}},[_vm._v(_vm._s(_vm.organization.name))])]),(_vm.app)?_c('div',{staticClass:"flex mt-3 abo"},[(_vm.app.admin_user_id == _vm.user.id && _vm.app.subscription)?_c('div',{staticClass:"flex w-full mb-1"},[_c('p',[_vm._v(_vm._s(_vm.$t("Abonnement"))+" :")]),_c('div',{staticClass:"relative ml-2"},[(
										_vm.app.subscription.plan.nickname == 'trial' ||
										_vm.app.subscription.plan.nickname == 'freemium'
									)?_c('vs-button',{staticClass:"detail text-xs",staticStyle:{"padding":"1px 5px"},attrs:{"type":"flat","icon-pack":"feather","icon":"icon-star","disabled":_vm.disabled},on:{"click":function($event){return _vm.subscribtionClick(_vm.app.url)}}},[_vm._v(" "+_vm._s(_vm.$t("S'abonner"))+" ")]):_c('vs-button',{staticClass:"detail text-xs",staticStyle:{"padding":"1px 5px"},attrs:{"type":"flat","icon-pack":"feather","icon":"icon-star","disabled":_vm.disabled},on:{"click":function($event){return _vm.subscribtionClick(_vm.app.url)}}},[_vm._v(" "+_vm._s(_vm.app.subscription.plan.nickname.toUpperCase())+" ")])],1)]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.app)?_c('div',[_c('div',{staticClass:"vs-row m-auto mt-2 flex w-full mr-5 users-list"},[_c('div',{staticClass:"flex"},[_vm._l((_vm.policies.slice(0, 5)),function(emp){return _c('img',{key:emp.id,staticClass:"avatar",class:{ 'avatar-admin': _vm.hoverable && !_vm.disabled },attrs:{"src":_vm.getImageUrl(emp.user),"width":"44px","height":"44px"}})}),_c('span',[_vm._v(" "+_vm._s(_vm.policies.length)+" ")]),(
									!_vm.app.subscription.plan.limits.users ||
									_vm.app.policies.length + _vm.app.invites.length < _vm.app.subscription.plan.limits.users
								)?_c('vs-tooltip',{staticClass:"tooltip",attrs:{"text":"Ajouter un utilisateur"}},[(_vm.app.admin_user_id == _vm.user.id)?_c('router-link',{staticClass:"btn-admin",attrs:{"tag":"button","to":{ name: 'Invite', params: { appId: _vm.app.id } },"aria-label":"ajouter un utilisateur","disabled":_vm.disabled}},[_c('vs-icon',{attrs:{"icon-pack":"feather","icon":"icon-user-plus"}})],1):_vm._e(),_c('span')],1):_vm._e(),_c('vs-tooltip',{staticClass:"tooltip",attrs:{"text":"Gérer les utilisateurs"}},[(_vm.app.admin_user_id == _vm.user.id)?_c('button',{staticClass:"btn-admin",attrs:{"aria-label":"Gérer les utilisateurs","disabled":_vm.disabled},on:{"click":function($event){return _vm.membersClick()}}},[_c('vs-icon',{attrs:{"icon-pack":"feather","icon":"icon-settings"}})],1):_vm._e(),_c('span')])],2)])]):_vm._e()])]),_c('div',{staticClass:"w-full flex-1 flex text-center mr-10"},[_c('div',{staticStyle:{"margin":"auto 0 auto auto"}},[_c('s-button',{staticClass:"m-auto",attrs:{"icon":"icon-arrow-right","icon-after":"true","disabled":_vm.disabled},on:{"click":function($event){return _vm.redirectTo(_vm.app.url)}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }